import { Box, Grid, Typography, styled } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import {} from "recharts";
import {
  BarChart,
  // BarPlot,
  // ChartsXAxis,
  // ChartsYAxis,
  LineChart,
  // LinePlot,
  // ResponsiveChartContainer,
  useDrawingArea,
} from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
// import { axisClasses } from "@mui/x-charts/ChartsAxis";
import ResumenEmisiones from "./ResumenEmisiones";
// import EmisionesHistoricas from "./EmisionesHistoricas";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 18,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2}
      sx={{ overflowWrap: "break-word" }}
    >
      {children}
    </StyledText>
  );
}

export default function MainSection({ emissions }) {
  const [scope, setScope] = useState(null);
  const [currentEmissions, setCurrentEmissions] = useState(null);
  const [emissionSummary, setEmissionSummary] = useState(null);
  const [yearsArray, setYearsArray] = useState(null);

  useEffect(() => {
    if (emissions) groupCurrentScope(emissions);
    // co2Summary(emissions);
  }, [emissions]);

  const groupCurrentScope = (data = {}) => {
    let scope = { s1: 0, s2: 0 };
    const emissions = [];
    const summary = [];
    const years = [];
    Object.keys(data).forEach((key) => {
      data[key].forEach((el) => {
        summary.push(el);
        years.push(el.year);
      });
    });
    const year = Math.max.apply(Math, years);
    data.mobile
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.stationary
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.refrigerant
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s1 += e.co2;
        emissions.push(e);
      });
    data.purchaseElectricity
      ?.filter((e) => e.year === year)
      .forEach((e) => {
        scope.s2 += e.co2;
        emissions.push(e);
      });
    setYearsArray([...new Set(years)]);
    setCurrentEmissions(emissions);
    setEmissionSummary(summary);
    setScope([scope]);
  };

  return (
    <>
      <Grid container spacing={0.5}>
        {Object.keys(emissions).length >0 && (<Grid item xs={12} sx={{backgroundColor:blue[300]}}>
            <Typography variant="h6" color={grey[50]} textAlign="center">RESUMÉN DE EMISIONES</Typography>
          </Grid>

        )}
        <ResumenEmisiones emissions={emissions} title={"Comparativa de reducción de emisiones"} />
        {scope?.length > 0 && (
          <Grid
          item
          xs={12}
          sx={{
            // borderRadius: "10px",
            // backgroundColor: grey[100],
            mb: 2,
          }}
          >
          <Typography variant="subtitle1" color={grey[600]} textAlign={"center"} marginBottom={4}>Scopes del último año registrado </Typography>
            <Grid container spacing={4}>
              {scope && scope.length > 0 && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 200,
                  }}
                >
                  <Box>
                    {scope[0].s1 ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          color={grey[600]}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {scope[0].s1.toLocaleString() + " Ton CO2"}
                        </Typography>
                        <PieChart
                          series={[
                            {
                              data: scope.map((e) => ({ value: e.s1 })),
                              innerRadius: 50,
                              outerRadius: 60,
                            },
                          ]}
                          margin={{ top: 20, bottom: 20, left: 30, right: 30 }}
                          width={200}
                          height={150}
                          colors={[green[500]]}
                        >
                          <PieCenterLabel>Scope 1</PieCenterLabel>
                        </PieChart>
                      </>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        color={grey[600]}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        No hay datos por mostrar en Scope 1
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
              {scope && scope.length > 0 && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 200,
                  }}
                >
                  <Box>
                    {scope[0].s2 ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          color={grey[600]}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {scope[0].s2.toLocaleString() + " Ton CO2"}
                        </Typography>
                        <PieChart
                          series={[
                            {
                              data: scope.map((e) => ({ value: e.s2 })),
                              innerRadius: 50,
                              outerRadius: 60,
                            },
                          ]}
                          margin={{ top: 20, bottom: 20, left: 30, right: 30 }}
                          width={200}
                          height={150}
                          colors={[green[500]]}
                        >
                          <PieCenterLabel>Scope 2</PieCenterLabel>
                        </PieChart>
                      </>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        color={grey[600]}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        No hay datos para este scope 2
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {currentEmissions && currentEmissions.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{
              // borderRadius: "10px",
              // backgroundColor: grey[100],
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" color={grey[600]}>
              Emisiones por Fuente y Tipo de Gas
              </Typography>
              <BarChart
                slotProps={{
                  legend: {
                    labelStyle: { fontSize: 12 },
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                  },
                }}
                colors={[
                  "#11972D",
                  "#017EBD",
                  "#E01483",
                  "#F89C29",
                  "#EA1C2F",
              ]}
                margin={{ left: 150, right: 50 }}
                width={550}
                height={150}
                layout="horizontal"
                dataset={currentEmissions}
                yAxis={[{ scaleType: "band", dataKey: "scope" }]}
                xAxis={[{ label: "Ton" }]}
                series={[
                  { dataKey: "co2", label: "CO2" },
                  { dataKey: "ch4", label: "CH2" },
                  { dataKey: "n2o", label: "N2O" },
                  { dataKey: "biofuel", label: "Biofuel" },
                ]}
              />
            </Box>
          </Grid>
        )}
        {emissionSummary && emissionSummary.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{ 
              // borderRadius: "10px", 
              // backgroundColor: grey[100] 
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <Typography variant="subtitle1" color={grey[600]}>
              Emisiones Históricas por Fuente
              </Typography>
              <LineChart
                width={600}
                height={150}
                margin={{ left: 100, right: 100 }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "top", horizontal: "middle" },
                    labelStyle: { fontSize: 12 },
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                  },
                }}
                series={[
                  {
                    data: emissionSummary
                      .filter((m) => m.scope === "S1 Combustion Estacionaria")
                      .map((m) => m.co2),
                    label: "Combustion Estacionaria",
                    showMark: true,
                    stack: "total",
                    area: true,
                  },
                  {
                    data: emissionSummary
                      .filter((m) => m.scope === "S1 Combustion Movil")
                      .map((m) => m.co2),
                    label: "Combustion Movil",
                    showMark: true,
                    stack: "total",
                    area: true,
                  },
                  {
                    data: emissionSummary
                      .filter((m) => m.scope === "S1 Refrigerante")
                      .map((m) => m.co2),
                    label: "Refrigerantes",
                    showMark: true,
                    stack: "total",
                    area: true,
                  },
                  {
                    data: emissionSummary
                      .filter((m) => m.scope === "S2 Electricidad")
                      .map((m) => m.co2),
                    label: "Electricidad",
                    showMark: true,
                    stack: "total",
                    area: true,
                  },
                ]}
                colors={[
                  "#11972D",
                  "#017EBD",
                  "#E01483",
                  "#F89C29",
                  "#EA1C2F",
              ]}
                xAxis={[
                  {
                    scaleType: "point",
                    position: "bottom",
                    data: yearsArray,
                  },
                ]}
                sx={{ ".MuiLineElement-root": { display: "none" } }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}

MainSection.defaultProps = {
  emissions: null,
};

MainSection.prototype = {
  emissions: Proptypes.array,
};
