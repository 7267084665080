import { Box, Grid } from '@mui/material'
import React from 'react'

export default function BarraColores() {
  
  return (
    <Grid container spacing={2} sx={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#11972D"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#017EBD"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#01AED9"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#E01483"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#F89C29"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#EA1C2F"}}></Box>

      </Grid>
      <Grid item xs={1.7}>
      <Box sx={{height:"1rem", backgroundColor:"#8F1837"}}></Box>

      </Grid>
    </Grid>
    // <Grid item xs={12} sx={{display:"grid", gridTemplateColumns:"12rem 12rem 12rem 12rem 12rem 12rem 12rem", gap:3, my:4}}>
    //   </Grid>
  )
}
