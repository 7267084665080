import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LeftAside from "./components/LeftAside";
import MainSection from "./components/MainSection";
import RightAside from "./components/RightAside";
import { getEdificios, getEmpresa } from "../../api/Core.api";
// import { enqueueSnackbar } from "notistack";
import { getEmissionsPerBuilding } from "../../api/Entregables";
import { useParams } from "react-router-dom";
// import ResumenEmisiones from "./components/ResumenEmisiones";
// import EmisionesHistoricas from "./components/EmisionesHistoricas";
import BarraColores from "../../components/BarraColores";
import { useReactToPrint } from "react-to-print";
// import Logo from "../../assets/logocuugreen.png";
import fondo from "../../assets/entregables/FONDO.png";
import { errorHandler } from "../../utils/ErrorHandler";

export default function EntregablesPDF() {
  const componentRef = useRef();
  const { facility, inc } = useParams();
  const [empresas, setEmpresas] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const [planta, setPlanta] = useState(facility);
  const [empresa, setEmpresa] = useState(inc);
  // const [display, setDisplay] = useState("none");
  const currentDate = new Date()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchEmpresas();
        await fetchPlantas();
      } catch (error) {
        console.log(error);
        errorHandler(error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadEmissions = async () => {
      try {
        await fetchEmissions(planta);
      } catch (error) {
        errorHandler(error)
      }
    };
    if (planta) loadEmissions();
  }, [planta]);

  const fetchEmpresas = async () => {
    try {
      const res = await getEmpresa();
      setEmpresas(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchPlantas = async () => {
    try {
      const res = await getEdificios();
      setPlantas(res.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchEmissions = async (planta) => {
    const build = {
      build_id: planta,
    };
    try {
      const res = await getEmissionsPerBuilding(build);
      const data = res.data;
      setEmissions({
        stationary: data.stationary.map((e) => ({
          year: e.yearf,
          scope: "S1 Comb. Estacionaria",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
        mobile: data.mobile.map((e) => ({
          year: e.yearf,
          scope: "S1 Comb. Movil",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n20) || 0,
        })),
        refrigerant: data.refrigerant.map((e) => ({
          year: e.yearf,
          scope: "S1 Refrigerante",
          co2: Number(e.total_co2) || 0,
          ch: 0,
          biofuel: 0,
          n2o: 0,
        })),
        purchaseElectricity: data.purchaseElectricity.map((e) => ({
          year: e.yearf,
          scope: "S2 Electricidad",
          co2: Number(e.total_co2) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
        transportation: data.transportation.map((e) => ({
          year: e.yearf,
          scope: "S3 Transporte",
          co2: Number(e.total_co2) || 0,
          biofuel: Number(e.total_biofuel) || 0,
          ch4: Number(e.total_ch4) || 0,
          n2o: Number(e.total_n2o) || 0,
        })),
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0.5}
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid item xs={12} md={12}></Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h2" textAlign="center">
            ¡Reporte listo!
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" textAlign="center">
            ¡Gracias por utilizar nuestra calculadora de emisiones de Co2!
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="body1">
            Valoramos tu solidaridad y compromiso al aportarnos tu valiosa
            información para calcular las emisiones de gases de efecto
            invernadero (GEI), de tu empresa u organización, lo que nos ayuda a
            dar un paso colectivo firme hacia un futuro más prometedor, verde y
            consciente para nuestro estado.
          </Typography>
          <Typography variant="body1">
            Este reporte es emitido a través de la metodología Green Gas
            Protocol, que ha sido probada a nivel mundial en un amplio número de
            empresas apra medir su huella de carbono.
          </Typography>
          <Typography variant="body1">
            Sigamos construyendo juntos un Chihuahua más competitivo, una
            sociedad más responsable y un medio ambiente más sano. ¡Enhorabuena!
            Con este documento puedes visualizar claramente el estatus de tu
            compañía y tomar decisiones en favor de ella, de nuestro entorno y
            nuestra sociedad.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <Button variant="contained" onClick={handlePrint}>
            Descargar
          </Button>
        </Grid>
        <BarraColores />
      </Grid>
      <Grid container spacing={2} ref={componentRef}>
        <Grid item xs={12} sx={{display:"flex", justifyContent:"center", alignItems:"baseline", gap:4}}>
          <Typography variant="h2" textAlign="center">
            REPORTE DE EMISIONES
          </Typography>
          <Typography variant="h4">{currentDate.getDate()}/{currentDate.getMonth()+1}/{currentDate.getFullYear()}</Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <LeftAside
            empresa={empresas.filter((e) => e.id === Number(empresa))[0]}
            planta={plantas.filter((e) => e.id === Number(planta))[0]}
          />
        </Grid>
        <Grid item xs={5} md={5}>
          <MainSection emissions={emissions} />
        </Grid>
        <Grid item xs={4} md={4}>
          <RightAside emissions={emissions} />
        </Grid>
        <Grid item xs={12} sx={{ px: 4, mx: 4 }}>
          <Typography variant="body1">
            Valoramos tu solidaridad y compromiso al aportarnos tu valiosa
            información para calcular las emisiones de gases de efecto
            invernadero (GEI), de tu empresa u organización, lo que nos ayuda a
            dar un paso colectivo firme hacia un futuro más prometedor, verde y
            consciente para nuestro estado.
          </Typography>
          <Typography variant="body1">
            Este reporte es emitido a través de la metodología Green Gas
            Protocol, que ha sido probada a nivel mundial en un amplio número de
            empresas apra medir su huella de carbono{" "}
          </Typography>
          <Typography variant="body1">
            En Chihuahua Green, creemos en la importancia de la transparencia y
            la responsabilidad ambiental, es por ello que te reiteramos que tus
            datos serán utilizados en el marco de la privacidad para tu mayor
            tranquilidad.
          </Typography>
          <Typography variant="body1">
            Sigamos construyendo juntos un Chihuahua más competitivo, una
            sociedad más responsable y un medio ambiente más sano. ¡Enhorabuena!
            Con este documento puedes visualizar claramente el estatus de tu
            compañía y tomar decisiones en favor de ella, de nuestro entorno y
            nuestra sociedad.
          </Typography>
        </Grid>
        <BarraColores />
      </Grid>
    </>
  );
}
