import { Box, Grid, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React from "react";
import logoGreen from "../../../assets/cuugreen_old.png"

export default function LeftAside({ empresa, planta }) {
  return (
    <>
      {empresa && (
        <Box
          sx={{
            px: 2,
            // backgroundColor: grey[200],
            // py: 1,
            // borderRadius: "10px",
          }}
        >
          <Grid container spacing={0.5}>
            <Grid item xs={12} md={12} sx={{ mb: 4, backgroundColor:blue[300] }}>
              <Typography
                variant="h6"
                color={grey[50]}
                sx={{ textAlign: "center" }}
              >
                EMPRESA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={logoGreen} alt="green logo" style={{maxWidth:"70px"}} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                R.F.C.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.rfc}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Nombre Fiscal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.nombre_fiscal}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Nombre Comercial
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.nombre_comercial}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Domicilio Fiscal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.dom_fiscal}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Contacto
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.contacto}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Puesto
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {empresa.puesto}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {planta && (
        <Box
          sx={{
            px: 2,
            // borderRadius: "10px",
            // backgroundColor: grey[50],
            py: 1,
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} sx={{ textAlign: "center", backgroundColor:blue[300] }}>
              <Typography variant="h6" color={grey[50]}>
                PLANTA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Alias
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.nick_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Calle
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.calle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Num. interior
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.num_int ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Num. exterior
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.num_ext}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Colonia
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.colonia}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Municipio
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.delegacion}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Estado
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.estado}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" color={grey[500]}>
                Codigo Postal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" display="block">
                {planta.cp}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
